import { ENETUNREACH } from "constants";
import MicroModal from 'micromodal';
import Swal from 'sweetalert2'

const text = `Narwhal has shut down (for now). However the team behind Narwhal is working on something very exciting. Our new project is called Narration Box, click the button below to find out more.`

const modal_triggers = document.querySelectorAll('.open-modal')
modal_triggers.forEach(item => {
  item.addEventListener('click', function () {
    Swal.fire({
      title: 'We have a message for you!',
      imageUrl: 'https://images.unsplash.com/photo-1518857555-349f02ebda69?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
      imageWidth: 600,
      imageHeight: 400,
      text: text,
      showCloseButton:true,
      confirmButtonText: "Go to Narration Box",
      grow: "fullscreen",
      background: "#000000",
      customClass: {
        title:"text-green-600",
        content: 'text-xl leading-normal font-semibold text-white max-w-3xl text-center self-center',
        confirmButton: 'bg-green-600 text-black text-xl font-semibold',
      },
      preConfirm: (login) => {
        window.location.href = "http://narrationbox.com";
      }
    })
  })
})


setInterval(function () {
  let animationName1 = "animated";
  let animationName2 = "fadeInUp";
  document.getElementById("rocket").classList.add(animationName1);
  document.getElementById("rocket").classList.add(animationName2);
  document
    .getElementById("rocket")
    .addEventListener("animationend", function () {
      this.classList.remove(animationName1);
      this.classList.remove(animationName2);
    });
}, 6000);

// Get all "navbar-burger" elements
const $navbarBurgers = Array.prototype.slice.call(
  document.querySelectorAll(".navbar-burger"),
  0
);

// Check if there are any navbar burgers
if ($navbarBurgers.length > 0) {
  // Add a click event on each of them
  $navbarBurgers.forEach(function ($el) {
    $el.addEventListener("click", function () {
      // Get the "main-nav" element
      const $target = document.getElementById("main-nav");

      // Toggle the class on "main-nav"
      $target.classList.toggle("hidden");
    });
  });
}

const animateInvertedLeft = function (target_for_animation) {
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      //entry.target.style.WebkitTransform="rotateY(30deg) skewY(15deg)";
      if (entry.isIntersecting) {
        entry.target.style.transform = "rotateY(0deg) skewY(0deg)";
        intersectionObserver.unobserve(entry.target);
      }

    });
  });

  const elements = [...document.querySelectorAll(target_for_animation)];
  elements.forEach(element => intersectionObserver.observe(element));
}

const animateFadeinRight = function (target_for_animation) {
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      //entry.target.style.WebkitTransform="rotateY(30deg) skewY(15deg)";
      if (entry.isIntersecting) {
        entry.target.classList.add('animated');
        entry.target.classList.add('fadeInRight');
        intersectionObserver.unobserve(entry.target);
      }

    });

  });

  const elements = [...document.querySelectorAll(target_for_animation)];
  elements.forEach(element => intersectionObserver.observe(element));
}

const animateFadeinLeft = function (target_for_animation) {
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      //entry.target.style.WebkitTransform="rotateY(30deg) skewY(15deg)";
      if (entry.isIntersecting) {
        entry.target.classList.add('animated');
        entry.target.classList.add('fadeInLeft');
        intersectionObserver.unobserve(entry.target);
      }
    });
  });

  const elements = [...document.querySelectorAll(target_for_animation)];
  elements.forEach(element => intersectionObserver.observe(element));
}

animateInvertedLeft(".inverted-left");
animateFadeinRight("#fade-right");
animateFadeinLeft("#fade-left");

